// ** Initial user ability
export const initialAbility = []
// [
//   {
//     action: 'read',
//     subject: 'Auth'
//   }
// ]

export const _ = undefined

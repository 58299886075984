// id: 0,
// title:"test",
// start:new Date("2022-03-27T14:12:04.592Z"),
// end:new Date("2022-03-27T14:12:04.592Z"),
// allDay:false,
// display:"block",
// color:"white",
// borderColor:"black",
// extendedProps:{
//     calendar:"Completed",
//     location:"3219 Shearwood Forest Drive, Center Harbor, New Hampshire, 03226"
// }

import Inspection from "./Inspection"
import AvailabilityModel from "./AvailabilityModel"

class ExtendedProps {
    constructor(props, isInspectionCalendar = true) {
        const {
            calendar,
            location,
            payload
        } = props

        this.calendar = calendar
        this.location = location

        if (isInspectionCalendar) {
            this.payload = new Inspection(payload)
        } else {
            this.payload = new AvailabilityModel(payload)
        }
    }
}

export default class Calendar {
    constructor(props, isInspections) {
        const {
            id,
            title,
            start,
            end,
            allDay,
            display,
            color,
            borderColor,
            extendedProps
        } = props

        this.id = id
        this.title = title
        this.start = start
        this.end = end
        this.allDay = allDay
        this.display = display
        this.color = color
        this.borderColor = borderColor
        this.extendedProps = new ExtendedProps(extendedProps, isInspections)
    }
} 
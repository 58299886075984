// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import inspector from './inspector'
import managers from './managers'
import notifications from './notifications'
import requests from '@src/views/requests/store/reducer'
import calendar from '@src/views/calendar/store/reducer'
import zipcodes from '@src/views/zipcodes/store/reducer'
import areas from '@src/views/areas/store/reducer'
import ancillaryServices from '@src/views/ancillary-services/store/reducer'
import assistantManagers from '@src/views/assistant-managers/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  calendar,
  inspector,
  requests,
  managers,
  notifications,
  zipcodes,
  areas,
  ancillaryServices,
  assistantManagers
})

export default rootReducer

import constants from "../../../../constants"
import { SET_INSPECTOR_DATA } from "../../../../redux/actions/inspectors"
import { SELECT_MANAGER } from "../../../../redux/actions/managers"
import {
  APPLY_INSPECTION_EVENTS,
  DELETE_AVAILABILITY,
  NO_DATA_FOUND,
  SET_AVAILABILITY_DATE,
  TOGGLE_EVENT_UPDATED
} from "../actionTypes"

import InspectionModelToAvailabilityModel from "../../Modal/InspectionModelToAvailabilityModel"
import AvailabilityModel from "../../Modal/AvailabilityModel"
import Inspection from "../../Modal/Inspection"
import Calendar from "../../Modal/Calendar"

// ** Initial State
const initialState = {
  viewModeInspections: true,
  availabilityDate: null,
  events: [],
  inspections: [],
  availability: [],
  selectedEvent: {},
  selectedCalendars: [],
  filterOptions: [],
  eventUpdated: false,
  selectedPastInspection: null
}

const calenderReducer = (state = initialState, action) => {
  switch (action.type) {
    // case 'ADD_EVENT':
    //   return { ...state }
    // case 'REMOVE_EVENT':
    //   return { ...state }
    // case 'UPDATE_EVENT':
    //   return { ...state }
    case TOGGLE_EVENT_UPDATED:
      return { ...state, eventUpdated: action.payload }
    case 'TOGGLE_VIEW':
      const { showInspections } = action
      return {
        ...state,
        viewModeInspections: showInspections,
        events: []
      }
    case 'FETCH_EVENTS':
      return { ...state, events: action.events }
    case 'ADD_AVAILABILITY':
      const existingState = { ...state }
      const existingEvents = [...existingState.events]
      existingEvents.push(action.value)
      return { ...state, events: existingEvents }
    case 'REMOVE_EVENT':
      const filteredEvents = state.events.filter(event => +event.id !== +action.value)
      return { ...state, events: filteredEvents }
    case 'UPDATE_EVENT':
      {
        const updatedEvents = [...state.events]
        const {
            availability_date,
            availability_time,
            // availability_day,
            end_date,
            end_time,
            // end_day,
            inspector_first_name,
            // inspector_last_name,
            // inspector_avtar,
            // agent_email,
            // agent_name,
            // agent_phone,
            // buyer_email,
            // buyer_name,
            // buyer_phone,
            // city,
            inspector_availability_master_id,
            order_master_id,
            order_status
            // state,
            // zipcode,
            // property_address,
            // quote_price,
            // is_agreement_signed,
            // order_modified_date,
            // payment_status,
            // notes,
            // cardpoint_payment_url,
            // sign_page_url,
            // actual_amount_paid,
            // base_service_charge,
            // report_published_at,
            // AncillaryServices
        } = action.payload
        const updatedEventData = new Calendar({
          id: inspector_availability_master_id,
          title: inspector_first_name,
          start: new Date(`${availability_date} ${availability_time}`),
          end: new Date(`${end_date} ${end_time}`),
          allDay: false,
          display: "block",
          color: "white",
          borderColor: "black",
          extendedProps:{
            calendar: order_status,
            location: "",
            payload: action.payload
          }
        }, true)
  
        const updatableIndex = updatedEvents.findIndex(event => +event.extendedProps.payload.order_master_id === +order_master_id)
  
        if (updatableIndex > -1) {
          updatedEvents[updatableIndex] = updatedEventData
        }
        return { ...state, events: updatedEvents, eventUpdated: updatableIndex > -1 }
      }
    case 'ADD_INSPECTION':
      {
        const updatedEvents = [...state.events]
        const {
            availability_date,
            availability_time,
            // availability_day,
            end_date,
            end_time,
            // end_day,
            inspector_first_name,
            // inspector_last_name,
            // inspector_avtar,
            // agent_email,
            // agent_name,
            // agent_phone,
            // buyer_email,
            // buyer_name,
            // buyer_phone,
            // city,
            inspector_availability_master_id,
            order_master_id,
            order_status
            // state,
            // zipcode,
            // property_address,
            // quote_price,
            // is_agreement_signed,
            // order_modified_date,
            // payment_status,
            // notes,
            // cardpoint_payment_url,
            // sign_page_url,
            // actual_amount_paid,
            // base_service_charge,
            // report_published_at,
            // AncillaryServices
        } = action.payload
        const eventData = new Calendar({
          id: inspector_availability_master_id,
          title: inspector_first_name,
          start: new Date(`${availability_date} ${availability_time}`),
          end: new Date(`${end_date} ${end_time}`),
          allDay: false,
          display: "block",
          color: "white",
          borderColor: "black",
          extendedProps:{
            calendar: order_status, // static to mark availability and booked inspection
            location: "",
            payload: action.payload
          }
        }, true)
        const finalEventsArr = [...updatedEvents, eventData]
        return { ...state, events: finalEventsArr }
      }
    case 'SCHEDULE_EVENT':
      {
        const updatedEvents = [...state.events]
        const {
            availability_date,
            availability_time,
            // availability_day,
            end_date,
            end_time,
            // end_day,
            inspector_first_name,
            // inspector_last_name,
            // inspector_avtar,
            // agent_email,
            // agent_name,
            // agent_phone,
            // buyer_email,
            // buyer_name,
            // buyer_phone,
            // city,
            inspector_availability_master_id,
            order_master_id,
            order_status
            // state,
            // zipcode,
            // property_address,
            // quote_price,
            // is_agreement_signed,
            // order_modified_date,
            // payment_status,
            // notes,
            // cardpoint_payment_url,
            // sign_page_url,
            // actual_amount_paid,
            // base_service_charge,
            // report_published_at,
            // AncillaryServices
        } = action.payload
        const updatedEventData = new Calendar({
          id: inspector_availability_master_id,
          title: inspector_first_name,
          start: new Date(`${availability_date} ${availability_time}`),
          end: new Date(`${end_date} ${end_time}`),
          allDay: false,
          display: "block",
          color: "white",
          borderColor: "black",
          extendedProps:{
            calendar: 'booked', // static to mark availability and booked inspection
            location: "",
            payload: action.payload
          }
        }, true)
  
        const updatableIndex = updatedEvents.findIndex(event => +event.extendedProps.payload.inspector_availability_master_id === +inspector_availability_master_id)

        if (updatableIndex > -1) {
          updatedEvents[updatableIndex] = updatedEventData
        }
        return { ...state, events: updatedEvents, eventUpdated: updatableIndex > -1 }
      }
    case "RESCHEDULE_AVAILABILITY":
      {
        const { payload } = action

        const {
          availability_date, // ": "07/14/2022",
          availability_time, // ": "01:00 PM",
          availability_day, // ": "Thursday",
          end_date, // ": "07/14/2022",
          end_time, // ": "02:30 PM",
          end_day, // ": "Thursday",
          availability_status, // ": "booked",
          inspector_availability_master_id, // ": "29491",
          order_master_id, // ": "17273",
          additional_detail, // ": "",
          avtar, // ": "https://api-sandbox.hdmk.net/images/inspector_avtars/1655439189ben-parker-NohB3FJSY90-unsplash_(2).jpg",
          first_name, // ": "Manan",
          user_master_id, // ": "2134",
          quote_price // ": "1.00"
        } = payload

        const existingState = {
          ...state
        }

        const existingEvents = [...existingState.events]

        const updateIndex = existingEvents.findIndex(event => event.extendedProps.payload.order_master_id === order_master_id)

        if (updateIndex > -1) {
          existingEvents[updateIndex] = new Calendar({
            id: inspector_availability_master_id,
            title: first_name,
            start: new Date(`${availability_date} ${availability_time}`),
            end: new Date(`${end_date} ${end_time}`),
            allDay: false,
            display: "block",
            color: "white",
            borderColor: "black",
            extendedProps:{
              calendar: availability_status,
              location: "",
              payload
            }
          }, false)
        }
        return {
          ...existingState,
          events: existingEvents
        }
      }
    case "RESCHEDULE_INSPECTION":
      {
        const { payload } = action

        const {
            availability_date, // ": "07/20/2022",
            availability_time, // ": "06:01 AM",
            // availability_day, // ": "Wednesday",
            end_date, // ": "07/20/2022",
            end_time, // ": "06:05 AM",
            // end_day, // ": "Wednesday",
            // inspector_user_master_id, // ": "2301",
            inspector_first_name, // ": "Vicky",
            // inspector_last_name, // ": "SST",
            // inspector_avtar, // ": "https://api-sandbox.hdmk.net/images/inspector_avtars/1658127745joseph-gonzalez-iFgRcqHznqg-unsplash_(2).jpg",
            // agent_email, // ": "",
            // agent_name, // ": "",
            // agent_phone, // ": "",
            // buyer_email, // ": "nishant.silversky@gmail.com",
            // buyer_name, // ": "nishant",
            // buyer_phone, // ": "123456789",
            // city, // ": "ahmedabad",
            inspector_availability_master_id, // ": "29533",
            order_master_id, // ": "17295",
            order_status // ": "accept",
            // state, // ": "GJ",
            // zipcode, // ": "380015",
            // property_address, // ": "SST",
            // quote_price, // ": "21.00",
            // is_agreement_signed, // ": "0",
            // order_modified_date, // ": "1658128558",
            // payment_status, // ": "pending",
            // notes, // ": "scheduling inspection",
            // cardpoint_payment_url, // ": "",
            // sign_page_url, // ": "https://api-sandbox.hdmk.net/Customer/Signature?order_master_id=MTcyOTU=",
            // actual_amount_paid, // ": "0",
            // base_service_charge, // ": "1",
            // report_published_at, // ": null,
            // AncillaryServices // ": []
        } = payload

        const existingState = {
          ...state
        }

        const existingEvents = [...existingState.events]
        const updateIndex = existingEvents.findIndex(event => event.extendedProps.payload.order_master_id === order_master_id)

        if (updateIndex > -1) {
          existingEvents[updateIndex] = new Calendar({
            id: inspector_availability_master_id,
            title: inspector_first_name,
            start: new Date(`${availability_date} ${availability_time}`),
            end: new Date(`${end_date} ${end_time}`),
            allDay: false,
            display: "block",
            color: "white",
            borderColor: "black",
            extendedProps:{
              calendar: order_status,
              location: "",
              payload
            }
          }, true)
        }
        return {
          ...existingState,
          events: existingEvents
        }
      }
    case "CANCEL_INSPECTION":
      {
        const { payload } = action

        const {
          isFromAvailability,
          data
        } = payload

        const {
            availability_date, // ": "07/20/2022",
            availability_time, // ": "06:01 AM",
            // availability_day, // ": "Wednesday",
            end_date, // ": "07/20/2022",
            end_time, // ": "06:05 AM",
            // end_day, // ": "Wednesday",
            // inspector_user_master_id, // ": "2301",
            inspector_first_name, // ": "Vicky",
            // inspector_last_name, // ": "SST",
            // inspector_avtar, // ": "https://api-sandbox.hdmk.net/images/inspector_avtars/1658127745joseph-gonzalez-iFgRcqHznqg-unsplash_(2).jpg",
            // agent_email, // ": "",
            // agent_name, // ": "",
            // agent_phone, // ": "",
            // buyer_email, // ": "nishant.silversky@gmail.com",
            // buyer_name, // ": "nishant",
            // buyer_phone, // ": "123456789",
            // city, // ": "ahmedabad",
            inspector_availability_master_id, // ": "29533",
            order_master_id, // ": "17295",
            order_status // ": "accept",
            // state, // ": "GJ",
            // zipcode, // ": "380015",
            // property_address, // ": "SST",
            // quote_price, // ": "21.00",
            // is_agreement_signed, // ": "0",
            // order_modified_date, // ": "1658128558",
            // payment_status, // ": "pending",
            // notes, // ": "scheduling inspection",
            // cardpoint_payment_url, // ": "",
            // sign_page_url, // ": "https://api-sandbox.hdmk.net/Customer/Signature?order_master_id=MTcyOTU=",
            // actual_amount_paid, // ": "0",
            // base_service_charge, // ": "1",
            // report_published_at, // ": null,
            // AncillaryServices // ": []
        } = data

        const existingState = {
          ...state
        }

        const existingEvents = [...existingState.events]
        const updateIndex = existingEvents.findIndex(event => event.extendedProps.payload.order_master_id === order_master_id)

        if (updateIndex > -1) {
          existingEvents[updateIndex] = new Calendar({
            id: inspector_availability_master_id,
            title: inspector_first_name,
            start: new Date(`${availability_date} ${availability_time}`),
            end: new Date(`${end_date} ${end_time}`),
            allDay: false,
            display: "block",
            color: "white",
            borderColor: "black",
            extendedProps:{
              calendar: isFromAvailability ? constants.available : order_status,
              location: "",
              payload: isFromAvailability ? new InspectionModelToAvailabilityModel(data) : data
            }
          }, !isFromAvailability)
        }
        return {
          ...existingState,
          events: existingEvents
        }
      }
    case 'UPDATE_FILTERS':
      // ** Updates Filters based on action filter
      let filteredEventsData = []

      const existingSelectedFilterOptions = [...state.selectedCalendars]

      const filterIndex = existingSelectedFilterOptions.findIndex(i => i === action.filter)
      if (filterIndex > -1) {
        existingSelectedFilterOptions.splice(filterIndex, 1)
      } else {
        existingSelectedFilterOptions.push(action.filter)
      }
      if (existingSelectedFilterOptions.length === 0) {
        state.events.length = 0
      } else {
        // state.selectedCalendars.length
        filteredEventsData = state.events.filter(event => {
          let returnVal = false
          returnVal = existingSelectedFilterOptions.some(filterOption => {
            const myReturnVal = filterOption.user_master_id === event.extendedProps.calendar
            return myReturnVal
          })
          return returnVal
        })
      }
      return { ...state, events: [...filteredEventsData], selectedCalendars: existingSelectedFilterOptions }
    case 'UPDATE_ALL_FILTERS':
      // ** Updates All Filters based on action value
      const value = action.value
      let selected = []
      if (value === true) {
        selected = state.filterOptions.map(option => option.user_master_id)
      } else {
        selected = []
      }
      return { ...state, selectedCalendars: [...selected] }
    case 'SELECT_EVENT':
      return { ...state, selectedEvent: action.event }
    case APPLY_INSPECTION_EVENTS:
      return { ...state, events: action?.payload }
    case SET_AVAILABILITY_DATE:
      return { ...state, availabilityDate: action?.value }
    case SET_INSPECTOR_DATA:
      const selectedCalendars = []
      const filterOptions = action.payload.map((option, index) => {
        selectedCalendars.push(option.user_master_id)
        return {
          ...option,
          className: index === action.payload.length - 1 ? 'custom-control-primary' : 'custom-control-primary mb-1'
        }
      })
      return { ...state, filterOptions, selectedCalendars }
    case NO_DATA_FOUND:
      return { ...state, events: [] }
    case DELETE_AVAILABILITY:
      {
        const updatedEvents = state?.events?.filter(event => event.extendedProps?.payload?.inspector_availability_master_id !== action.payload)
        return { ...state, events: updatedEvents ?? [] }
      }
    case SELECT_MANAGER:
    case 'LOGOUT':
      return initialState
    case 'SELECT_PAST_INSPECTION':
      return { ...state, selectedPastInspection: action.payload }
    default:
      return state
  }
}

export default calenderReducer

import axios from '../../../axios'
import { customLog, showToast, getAccessToken } from '../../../utility/Utils'

import Manager from '../../../Modal/Manager'
import { updateNotificationCount } from '../notifications'

export const GET_MANAGERS = 'GET_MANAGERS'

export const TOGGLE_MANAGERS_LOADER = 'TOGGLE_MANAGERS_LOADER'

export const toggleManagerLoader = (dispatch) => {
  dispatch({
    type: TOGGLE_MANAGERS_LOADER
  })
}

export const SELECT_MANAGER = 'SELECT_MANAGER'

export const GET_MANAGER_DATATABLE_DATA = 'GET_MANAGER_DATATABLE_DATA'

export const GET_BLIPP_REGION_LIST = 'GET_BLIPP_REGION_LIST'

export const GET_SUB_MANAGER_TOKEN = 'GET_SUB_MANAGER_TOKEN'

export const selectManager = (selectedManagerObj) => {
  return {
    type: SELECT_MANAGER,
    payload: new Manager(selectedManagerObj)
  }
}

export const getManagers = () => {
  return async (dispatch, getState) => {
    try {
      // const { userData } = getState().auth

      const userData = localStorage.getItem('userData')
      const userDataParsed = JSON.parse(userData)

      if (userDataParsed && userDataParsed?.user_master_id) {
        const formData = new FormData()
        formData.append('user_master_id', userDataParsed.user_master_id)

        const getManagersRes = await axios({
          url: 'WebAPI/ManagerParentController/getManagers',
          method: 'POST',
          data: formData
        })

        const {
          data, // ": [],
          ResponseCode, // ": 1,
          ResponseMsg, // ": "",
          Result, // ": "True",
          ServerTime, // ": "UTC",
          RequestId // ": "8d78a2ff87f95156c73d433bdfeb0416a1b47e6f5b6083ea1e7e4801bb66099c"
        } = getManagersRes.data

        if (ResponseCode && Result.toLowerCase() === 'true') {
          dispatch({
            type: GET_MANAGERS,
            payload: data
          })
        } else {
          showToast('', ResponseMsg, true)
          throw new Error('failed')
        }
      }
    } catch (err) {
      customLog('[getManagers] Error : ', err.message)
      throw new Error(err.message)
    }
  }
}

export const getSubManagers = () => {
  return async (dispatch, getState) => {
    try {
      const { data: response } = await axios.get('/manager/sub-manager')

      const authUser = getState().auth.userData

      dispatch({
        type: GET_MANAGERS,
        payload: response?.payload?.data.map((manager) => {
          if (
            authUser?.admin_register_master_id ===
            manager?.admin_register_master_id
          ) {
            return { ...manager, access_token: getAccessToken() }
          }

          return manager
        })
      })
    } catch (err) {
      customLog('[getSubManagers] Error : ', err?.response?.data?.message)
      throw new Error(err?.response?.data?.message)
    }
  }
}

export const getSubManagerToken = (subManagerId) => {
  return async (dispatch) => {
    const accessToken = getAccessToken()
    return axios
      .post(
        `/sub-manager/${subManagerId}/token`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then(({ data }) => {
        dispatch({
          type: GET_SUB_MANAGER_TOKEN,
          payload: data?.payload
        })

        dispatch(
          updateNotificationCount(data?.payload?.unread_notification_count)
        )

        return data
      })
  }
}

export const getManagerList = () => {
  return async (dispatch, getState) => {
    try {
      const { data: response } = await axios.get('/manager')

      return response?.payload
    } catch (err) {
      customLog('[getManagerList] Error : ', err.message)
      throw new Error(err.message)
    }
  }
}

export const getManagerDataTable = (params) => {
  return async (dispatch) => {
    await axios
      .get('/manager/datatable', {
        params
      })
      .then((response) => {
        dispatch({
          type: GET_MANAGER_DATATABLE_DATA,
          payload: {
            data: response.data.payload.data,
            totalPages: response.data.payload.meta.last_page,
            params
          }
        })
      })
  }
}

export const getBlippRegionList = () => {
  return async (dispatch, getState) => {
    return await axios.get('/blippregion').then(({ data }) => {
      dispatch({
        type: GET_BLIPP_REGION_LIST,
        payload: data.payload.data
      })
    })
  }
}

export const createManager = (payload) => {
  return async () => {
    return await axios.post('/manager', payload).then(({ data }) => {
      const { message } = data
      showToast('', message, false)
      return data
    })
  }
}

export const updateManager = (id, payload) => {
  return async () => {
    return await axios.put(`/manager/${id}`, payload).then(({ data }) => {
      const { message } = data
      showToast('', message, false)
      return data
    })
  }
}

export const getManager = (id) => {
  return async () => {
    return await axios.get(`/manager/${id}`)
  }
}

export const changeManagerPassword = (id, payload) => {
  return async () => {
    return await axios
      .put(`/manager/${id}/password`, payload)
      .then(({ data }) => {
        const { message } = data
        showToast('', message, false)
        return data
      })
  }
}

export const getManagerAssignedInspector = (id) => {
  return async () => {
    return await axios.get(`/manager/${id}/inspector`)
  }
}

export const getManagerRemainingAssignInspector = () => {
  return async () => {
    return await axios.get(`/inspector`)
  }
}

export const managerAssignInspector = (id, payload) => {
  return async () => {
    return await axios
      .post(`/manager/${id}/inspector`, payload)
      .then(({ data }) => {
        const { message } = data
        showToast('', message, false)
        return data
      })
  }
}

export const getManagerAssinedArea = (id, payload) => {
  return async () => {
    return await axios.post(`/manager/${id}/area`, payload).then(({ data }) => {
      return data
    })
  }
}

export const managerAssignInspectorArea = (id, payload) => {
  return async () => {
    return await axios
      .post(`/inspector/${id}/dayschedule`, payload)
      .then(({ data }) => {
        const { message } = data
        showToast('', message, false)
        return data
      })
  }
}

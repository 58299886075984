import astManagerActionTypes from '../actionTypes'

const initialState = {
  datatable: {
    data: [],
    total: 1
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case astManagerActionTypes.GET_ASSISTANT_AMANGER_DATATABLE:
      return {
        ...state,
        datatable: {
          data: payload?.data,
          total: payload?.totalPages,
          params: payload?.params
        }
      }
    default:
      return state
  }
}
